@font-face {
font-family: '__sf_2bf119';
src: url(/_next/static/media/7fee29fde19b19e3-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
}@font-face {font-family: '__sf_Fallback_2bf119';src: local("Arial");ascent-override: 101.50%;descent-override: 25.71%;line-gap-override: 0.00%;size-adjust: 93.80%
}.__className_2bf119 {font-family: '__sf_2bf119', '__sf_Fallback_2bf119';font-weight: 400
}

@font-face {
font-family: '__coa_683a34';
src: url(/_next/static/media/6bcc0f72ffaa204d-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__coa_Fallback_683a34';src: local("Arial");ascent-override: 96.47%;descent-override: 23.43%;line-gap-override: 0.00%;size-adjust: 93.99%
}.__className_683a34 {font-family: '__coa_683a34', '__coa_Fallback_683a34'
}

@font-face {
font-family: '__cos_95ebac';
src: url(/_next/static/media/e7fd2fef6a617801-s.p.ttf) format('truetype');
font-display: swap;
}@font-face {font-family: '__cos_Fallback_95ebac';src: local("Arial");ascent-override: 99.76%;descent-override: 24.23%;line-gap-override: 0.00%;size-adjust: 90.89%
}.__className_95ebac {font-family: '__cos_95ebac', '__cos_Fallback_95ebac'
}

@font-face {
font-family: '__cotc_9eadf9';
src: url(/_next/static/media/145e76f1a2f906be-s.p.otf) format('opentype');
font-display: swap;
}@font-face {font-family: '__cotc_Fallback_9eadf9';src: local("Arial");ascent-override: 100.00%;descent-override: 28.00%;line-gap-override: 0.00%;size-adjust: 100.00%
}.__className_9eadf9 {font-family: '__cotc_9eadf9', '__cotc_Fallback_9eadf9'
}

/* cyrillic-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_b9fe9d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ebec2867f40f78ec-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C8A, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__IBM_Plex_Mono_b9fe9d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d607327a37a507c7-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__IBM_Plex_Mono_b9fe9d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2e1b830192b7974a-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__IBM_Plex_Mono_b9fe9d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3aa27b2eb5f698f7-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__IBM_Plex_Mono_b9fe9d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3478b6abef19b3b3-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__IBM_Plex_Mono_Fallback_b9fe9d';src: local("Arial");ascent-override: 77.95%;descent-override: 20.91%;line-gap-override: 0.00%;size-adjust: 131.49%
}.__className_b9fe9d {font-family: '__IBM_Plex_Mono_b9fe9d', '__IBM_Plex_Mono_Fallback_b9fe9d';font-weight: 400;font-style: normal
}

@keyframes pulsestates {
  from {
    transform: scale(1);
    opacity: 0.4;
  }

  to {
    transform: scale(1.05);
    opacity: 1;
  }
}

.pulse {
  animation-duration: 1s;
  animation-name: pulsestates;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: cubic-bezier(0.17, 0.52, 1, 1);
}

.pulse-bg {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 1);
  }
  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
  }
}

.token.comment,
.token.default {
  color: var(--chakra-colors-white);
}

.token.highlight {
  color: var(--chakra-colors-pinkHighlight);
  text-decoration: underline;
}

